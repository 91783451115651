import React from "react";

export default (props) => {
  return (
    // original in: /images/check.svg
    <svg viewBox="0 0 78.369 78.369" {...props}>
      <path d="M78.049 19.015L29.458 67.606a1.094 1.094 0 01-1.548 0L.32 40.015a1.094 1.094 0 010-1.547l6.704-6.704a1.095 1.095 0 011.548 0l20.113 20.112 41.113-41.113a1.095 1.095 0 011.548 0l6.703 6.704a1.094 1.094 0 010 1.548z" />
    </svg>
  );
};
