import React from "react";
import SelectDropdown from "../form/select-dropdown";
import SelectDropdownUp from "../form/select-dropdown-up";
import PropTypes from "prop-types";

export default function SelectProductVariant (
  {
    product,
    value,
    isUp,
    onChange,
    hasWhite,
    productDosage
  }
) {

  const subscriptionProduct = (product.grouped_products_nodes || []).find(p => p.type === "subscription" && p.slug.indexOf(productDosage) !== -1);
  const subscriptionPrice = subscriptionProduct ? subscriptionProduct.price : null;
  const regularSubscriptionPrice = subscriptionProduct ? subscriptionProduct.regular_price : null;

  const simpleProduct = (product.grouped_products_nodes || []).find(p => p.type === "simple" && p.slug.indexOf(productDosage) !== -1);
  const simplePrice = simpleProduct ? simpleProduct.price : null;
  const regularSimplePrice = simpleProduct ? simpleProduct.regular_price : null;

  let selectedProductVariant =  { value: "subscription",  tag: "$"+subscriptionPrice, divider: " | ", subTag: "SUBSCRIBE & SAVE 15%", regularPrice: "$"+regularSubscriptionPrice, slug: product.slug };
  
  let restProductVariant = [
    { value: "simple",  tag: "$"+simplePrice, divider: " | ", subTag: "ONE-TIME PURCHASE", regularPrice: "$"+regularSimplePrice, slug: product.slug }
  ];
  return (
    (typeof isUp != "undefined" && isUp) ?
    <SelectDropdownUp selectedOption={selectedProductVariant} restOptions={restProductVariant} onChange={onChange}/>
    :
    <SelectDropdown selectedOption={selectedProductVariant} restOptions={restProductVariant} onChange={onChange} hasWhite={hasWhite}/>


  );
}

SelectProductVariant.propTypes = {
  product: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
