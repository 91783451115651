import React from "react";
import PropTypes from "prop-types";
import GlobalModals from "./global-modals";
import GlobalHelmet from "./global-helmet";

const LayoutFullpage = ({ isCartModalOpen, handleCartModalClose, newCartItem, localCart, children }) => {
  // let secretExists
  // if (typeof (Storage) !== "undefined") {
  //   secretExists = JSON.parse(localStorage.getItem("secret"));
  // }

  return (
    <>


      {children}
      <GlobalModals isCartModalOpen={isCartModalOpen} handleCartModalClose={handleCartModalClose} newCartItem={newCartItem} localCart={localCart}/>
      <GlobalHelmet />

    </>
  );
};

LayoutFullpage.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutFullpage;
