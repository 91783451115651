import React from "react";
import "./accordion.scss";
import anime from "animejs/lib/anime.es.js";
import WatchedElement from "./watchedElement";

const VariableHighlightedLabel = props => {
  const handleEnterViewportScrollDown = () => {
    if (props.animatesOnScroll) {
      anime.remove("." + props.className);

      anime({
        targets: "." + props.className,
        width: "100%",
        easing: "easeInOutQuad",
        duration: 1000,
      });
    }
  };

  const handleExitViewportScrollUp = () => {
    if (props.animatesOnScroll) {
      anime.remove("." + props.className);
      anime({
        targets: "." + props.className,
        width: "0%",
        easing: "easeInOutQuad",
        duration: 1000,
      });
    }
  };

  return (
    <WatchedElement
      handleEnterViewportScrollDown={handleEnterViewportScrollDown}
      handleExitViewportScrollUp={handleExitViewportScrollUp}>
      <hr
        id={props.id}
        className={props.className}
        style={{
          height: "1px",
          width: "0%",
          backgroundColor: props.color,
        }}
      />
    </WatchedElement>
  );
};

export default VariableHighlightedLabel;
