import React, { Component } from "react";
import AnimatedDoneButton from "./animated-done-button";

export default class AddToCartButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      added: false
    };
  }

  handleClick = () => {
    this.props.onClick && this.props.onClick();

    // play animation
    this.setState({ added: true });
    this.animTimer && clearTimeout(this.animTimer);
    this.animTimer = setTimeout(() => this.setState({ added: false }), 2000);
  };

  componentWillUnmount () {
    this.animTimer && clearTimeout(this.animTimer);
  }

  render () {
    return (
      <AnimatedDoneButton
        onClick={this.handleClick}
        done={this.state.added}
        className="button is-primary is-inline add-to-cart-button is-uppercase"
        normalText="Add To Bag"
        doneText="Added"
      />
    );
  }
}
