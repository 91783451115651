import React, { Component } from "react";
import "./animated-done-button.scss";
import CheckIcon from "../icons/check-icon";

export default class AnimatedDoneButton extends Component {

  componentWillUnmount () {
    this.animTimer && clearTimeout(this.animTimer);
  }

  render () {

    let btnClassName = (this.props.className || "") + " animated-done-button";
    if (this.props.done) btnClassName += " done";

    const propUseCheckIcon = this.props.useCheckIcon;
    const useCheckIcon = (propUseCheckIcon !== null && propUseCheckIcon !== undefined) ? propUseCheckIcon : true;

    return (
      <button type={this.props.type || "button"} onClick={this.props.onClick} className={btnClassName}>
        <span className="normal-text">{this.props.normalText || "Submit"}</span>
        <span className="done-text">{this.props.doneText || "Done"}
          {useCheckIcon && <CheckIcon className="check-icon" />}
        </span>
      </button>
    );
  }
}
