import React, { Component } from "react";
import "./reviews/review-modal.scss";
import auth from "../../lib/auth";
import Rejoiner from "../../lib/tracking/rejoiner";
import {  validatePhone } from "../../lib/helpers";
import AnimatedDoneButton from "../form/animated-done-button";
import axios from "axios";
import _get from "lodash.get";

export default class NotifyMeModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isActive: false,

      readonly_name: false,
      readonly_email: false,
      name: '',
      email: '', 
      phone: '',
      error_email: null,
      error_phone: null,
      sending: false,
      show_sent: false
    };
  }

  getFormData = () => ({
    name: this.state.name,
    email: this.state.email,
    phone: this.state.phone
  });

  open = () => {
    const state = { isActive: true };

    // fill name & email inputs from auth user and make these readonly
    if (auth.loggedIn) {
      state.name = auth.user.name;
      state.email = auth.user.email;
      state.readonly_name = !!state.name;
      state.readonly_email = !!state.email;
    }

    this.setState(state);
  };

  close = () => {
    this.setState({ isActive: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const formData = this.getFormData();
    let phone = validatePhone(formData.phone);
    // check has rating
    if (!phone) {
      return this.setState({
        error_phone: "Please fill in a valid phone number"
      });
    }

    // sending review
    this.setState({ sending: true });
    /*
    Rejoiner.subscribeToList({
      email: this.state.email,
      first_name: this.state.name,
      phone: this.state.phone,
      list_id: 'WNmOAZb'
    });
    */
    const api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-notify`
    const listFormData = { api_key: "pk_a67d8ed7894f8e1e56ada9eb616df05f7a",profiles: [{email: this.state.email, phone_number: this.state.phone}] }
    axios.post(api_url, listFormData)
    this.setState({ sending: false, show_sent: true });

    // callback
    // this.props.afterSent && this.props.afterSent();
  };

  render () {

    return (
      <div id="review-modal" className={`modal review-modal ${this.state.isActive ? "is-active" : ""}`}>
        <div className="modal-background" />
        <form className="modal-card" onSubmit={this.onSubmit}>

          {this.state.show_sent ?
            <section className="modal-card-body section has-text-centered">
              <br />
              <br />
              <h1 className="title is-y-marginless">Thank you!</h1>
              <p>We'll notify you when it's available</p>
              <br />
              <br />
            </section>
            :

            <section className="modal-card-body">
              <h1 className="title">Notify Me + 20% Off</h1>
              <p>Hey there!</p>
              <p>Due to popular demand and issues with restocking during COVID-19, we have temporarily ran out of Passion Fruit Gummies until mid-October.</p>
              <p>Please feel free to leave your contact info below. We'll let you know as soon as they're ready and we'll send you a 20% off coupon for the trouble!</p>
              <br/>
              {/* name */}
              <div className="field field-name">
                <div className="control">
                  <input className="input" type="text" name="name" placeholder="Name"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                    required readOnly={this.state.readonly_name} />
                </div>
              </div>

              {/* email */}
              <div className="field field-email">
                <div className="control">
                  <input className="input" type="email" name="email" placeholder="E-mail"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                    required readOnly={this.state.readonly_email} />
                </div>
              </div>

              {/* phone */}
              <div className="field field-phone">
                <div className="control">
                  <input className="input" type="phone" name="phone" placeholder="Phone"
                    value={this.state.phone}
                    onChange={e => this.setState({ phone: e.target.value })}
                    required />
                </div>
                <div className="column">
                    {this.state.error_phone ?
                      <p className="has-text-danger">{this.state.error_phone}</p> : false}
                  </div>
              </div>

              {/* submit */}
              <div className="field field-submit">
                <AnimatedDoneButton type="submit"
                  className={`button is-primary is-normal is-fullwidth ${this.state.sending ? "is-loading" : ""}`}
                  done={this.state.show_sent}
                  normalText="SUBMIT"
                  doneText="YOUR EMAIL SENT"
                />
              </div>

            </section>

          }
          <button type="button" className="modal-close is-large" onClick={this.close} aria-label="close" />
        </form>
      </div>
    );
  }
}
