import React, { Component } from "react";
import "./select-dropdown.scss";

export default class SelectDropdownUp extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          selectedOption: props.selectedOption,
          restOptions: props.restOptions
        };
    }
  

    handleSelect(value) {
        const selectedOption = this.state.restOptions.find( option => option.value === value);
        var restOptions = this.state.restOptions.filter( option => option.value !== value);
        restOptions.push(this.state.selectedOption);
        this.setState({selectedOption:selectedOption, restOptions:restOptions});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedOption.tag !== this.props.selectedOption.tag || (prevProps.selectedOption.hasOwnProperty("slug") && prevProps.selectedOption.slug !== this.props.selectedOption.slug)){
            this.setState({selectedOption: this.props.selectedOption,restOptions: this.props.restOptions})
        }
    } 

    render() {
        const { type, onChange } = this.props;
        return(
            typeof this.state.selectedOption != "undefined" ?
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <div className={`dropdown is-up is-hoverable ${"color" === type ? "flavor-selection":""}`}>
                        <div className="dropdown-trigger">
                            <button className="button bottom-dropdown" aria-haspopup="true" aria-controls="dropdown-menu">
                                { "color" === type ?
                                    <img src={require(`../../images/effects/${this.state.selectedOption.color}`)} style={"mighty-mango-energize.svg"===this.state.selectedOption.color?{height:'31px',marginRight:'10px'}:{marginRight:'10px'}} alt=""/>
                                    :
                                    false
                                }
                                <span className="main-tag">
                                    {this.state.selectedOption.tag}
                                </span>
                                <span className="sub-tag">
                                    {this.state.selectedOption.divider}{this.state.selectedOption.subTag}
                                </span>
                                <span className="fa-stack">
                                    <i className="far fa-angle-up"></i>
                                    <i className="far fa-angle-down"></i>
                                </span>
                            </button>
                        </div>
                        {this.state.restOptions.length > 0 ?
                            <div className="dropdown-menu bottom-dropdown-menu" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    {this.state.restOptions.map((option, key) =>
                                        <a key={key} className={`dropdown-item ${"disabled" === option.status ? "is-disable":""}`} onClick={() => {if("disabled" !== option.status){ this.handleSelect(option.value);onChange(option.value)}}}>
                                            { "color" === type ?
                                                <img src={require(`../../images/effects/${option.color}`)} style={"mighty-mango-energize.svg"===option.color?{height:'31px',marginRight:'10px'}:{marginRight:'10px'}} alt=""/>
                                                :
                                                false
                                            }
                                            <span className="main-tag">
                                                {option.tag}
                                            </span>
                                            <span className="sub-tag">
                                                {option.divider}{option.subTag}
                                            </span>
                                        </a>
                                        )}
                                        
                                </div>
                            </div>
                        :
                        false
                        }
                    </div>
                    </div>
                    </div>
                    :
                    false
        );
    }

}
